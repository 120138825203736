import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from './AppStoreIcon'
import {
  Button,
  Colors,
  Column,
  H1,
  H2,
  MediaQuery,
  Row,
  Text
} from '../styles'
import PageContainer from './PageContainer'
import ButtonSignup from './ButtonSignup'
import DownloadButtons from './DownloadButtons'
import ButtonSamplePDF from './ButtonSamplePDF'

const Image = styled.img`
  height: 400px;
  @media ${MediaQuery.Large} {
    height: 850px;
  }
`

const Hero = styled(Row)`
  padding: 50px 0;
  align-items: center;
  flex: 1;
  flex-direction: column-reverse;
  @media ${MediaQuery.Large} {
    flex-direction: row;
  }
`
const LeftSide = styled.div`
  display: flex;
  flex: 1;
  /* margin-right: 40px; */
  /* flex-direction: row; */
  justify-content: flex-start;
`

const RightSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`

const Wrap = styled.div`
  display: flex;
  flex: 1;
  max-width: 1920px;
  margin: 0 auto;
  background: #0b182d;
  color: ${Colors.pink};

  /* background: url('/static/bg.jpg') no-repeat center;
  background-size: cover; */
`

const Title = styled(H1)`
  margin: 30px 5px 0 5px;
  text-transform: none;
  text-align: center;
  @media ${MediaQuery.Medium} {
    margin: 40px 0;
    padding: 0 80px;
  }
  @media ${MediaQuery.Large} {
    margin: 30px 0;
    /* text-align: left; */
    padding: 0;
  }
`

const Description = styled(Text)`
  display: block;
  /* display: inline-flex; */
  /* align-items: center; */
  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: center;
  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    /* text-align: left; */
    /* max-width: 800px; */
    padding: 0 0 20px 0;
    margin: 0;
  }
`

const CTA = styled(Button)`
  width: 580px;
  @media ${MediaQuery.Small} {
    width: 90%;
    margin: 20px 0 0 0;
    margin: 0 auto;
  }
  @media ${MediaQuery.Medium} {
    margin: 40px 0;
    margin: 0 auto;
  }
`

const PDFCard: React.FC = () => {
  return (
    <Wrap>
      <PageContainer>
        <Hero>
          <Column>
            <StaticImage
              style={{ width: '100%' }}
              src="../../static/images/Portfolio.svg"
              alt="My School Files"
            />
            <Title>Download a PDF of your student's portfolio</Title>
            <Description>
              Generate a hard copy of your student's portfolio with the tap of a
              button.
            </Description>
            <CTA target="_blank" href="/sample-portfolio.pdf">
              View Sample PDF
            </CTA>
            {/* <DownloadButtons /> */}
          </Column>
        </Hero>
      </PageContainer>
    </Wrap>
  )
}

export default PDFCard
