import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from './AppStoreIcon'
import {
  blueGradientMixin,
  Button,
  Colors,
  Column,
  H1,
  H2,
  List,
  MediaQuery,
  Row,
  Text
} from '../styles'
import PageContainer from './PageContainer'
import ButtonLearnMore from './ButtonLearnMore'
import { FaHeart } from 'react-icons/fa'
import ButtonSignup from './ButtonSignup'

interface Props {
  bgColor?: string
}

const Hero = styled(Column)`
  padding: 40px 0;
  align-items: center;
  background: ${(p: Props) => p.bgColor || '#efefef'};
  background-size: cover;
  /* ${blueGradientMixin} */

  flex: 1;
`

const ImageWrap = styled.div`
  width: 100%;

  /* width: 30px; */
  @media ${MediaQuery.Medium} {
    width: 85%;
  }
  @media ${MediaQuery.Large} {
    width: 60%;
  }
`

const LeftSide = styled.div`
  display: flex;
  flex: 1;
  /* margin-right: 40px; */
  /* flex-direction: row; */
  justify-content: flex-start;
`

const RightSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const Wrap = styled.div`
  display: flex;
  flex: 1;
  max-width: 1920px;
  margin: 0 auto;
  background: ${Colors.white};
`

const AppDescription = styled(H1)`
  /* color: #fff; */
  margin: 30px 20px 20px 20px;
  text-transform: none;
  text-align: center;
  @media ${MediaQuery.Medium} {
    text-align: left;
    margin: 80px 0 40px 0;
  }
`

const CTA = styled(Button)`
  width: 580px;
  @media ${MediaQuery.Small} {
    width: 90%;
    margin: 20px 0 0 0;
  }
  @media ${MediaQuery.Medium} {
    margin: 40px 0;
  }
`

const PasscodeWrap = styled.p`
  font-size: 2rem;
  span.code {
    font-weight: bold;
  }
`

const Container = styled(PageContainer)`
  display: flex;

  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
`

const ShareHowToCard: React.FC<Props> = (props) => {
  return (
    <Wrap>
      <Hero {...props}>
        <RightSide>
          {/* <Image
            src="/static/screenshots/ios/share-three.png"
            alt="My school files App"
          /> */}
          <ImageWrap>
            <StaticImage
              style={{ width: '100%', height: '100%' }}
              src="../../static/images/screenshots/ios/share-three.png"
              alt="My School Files"
            />
          </ImageWrap>
        </RightSide>
        <LeftSide>
          <Column>
            <Container>
              <AppDescription>Share your Student's Portfolio</AppDescription>
              <List style={{ maxWidth: 700 }}>
                <li>Securely share with family, friends, and evaluators.</li>
                <li>
                  Live Portfolio - Once you generate a share link, it will
                  always be up to date with any changes you make.
                </li>
              </List>
              <CTA
                target="_blank"
                href={process.env.GATSBY_SAMPLE_PORTFOLIO_URL}
              >
                View Sample Portfolio
              </CTA>
              <PasscodeWrap>
                Passcode:{' '}
                <span className="code">
                  {process.env.GATSBY_SAMPLE_PORTFOLIO_PASSCODE}
                </span>
              </PasscodeWrap>
            </Container>
          </Column>
        </LeftSide>
      </Hero>
    </Wrap>
  )
}

export default ShareHowToCard
