import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

interface Props {
  fadeInOnce?: boolean
}

const FadeInViewport: React.FC<Props> = ({ fadeInOnce = true, children }) => {
  const [didFadeIn, setDidFadeIn] = useState(false)

  const { inView, ref, entry } = useInView({
    // triggerOnce: true,
    // threshold: 0.35
  })
  const variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1
    }
  }
  return (
    <motion.div
      onAnimationComplete={(e: string) => {
        if (e === 'visible') {
          setDidFadeIn(true)
        }
      }}
      transition={{
        duration: 0.8,
        delay: 0.1
      }}
      animate={(inView && !didFadeIn) || didFadeIn ? 'visible' : 'hidden'}
      variants={variants}
      ref={ref}
    >
      {children}
    </motion.div>
  )
}

export default FadeInViewport
