import * as React from 'react'
import styled from 'styled-components'
import { MediaQuery } from '../styles'

const Page = styled.div`
  width: 100%;
  @media ${MediaQuery.Large} {
    width: 1200px;
    margin: 0 auto;
  }
`

const PageContainer: React.FC = ({ children, ...rest }) => (
  <Page {...rest}>{children}</Page>
)

export default PageContainer
